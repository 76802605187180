import { useMelwaysInfo } from '@seek/melways-react';
import {
  Box,
  ButtonLink,
  Heading,
  IconArrow,
  IconTick,
  List,
  Stack,
  Text,
  TextLink,
} from 'braid-design-system';
import type { ReactNode } from 'react';

import { useReturnUrl } from 'src/hooks/useReturnUrl';

import promoteSignInImageAU from './images/au.png';
import promoteSignInImageHK from './images/hk.png';
import promoteSignInImageID from './images/id.png';
import promoteSignInImageMY from './images/my.png';
import promoteSignInImageNZ from './images/nz.png';
import promoteSignInImagePH from './images/ph.png';
import promoteSignInImageSG from './images/sg.png';
import promoteSignInImageTH from './images/th.png';

import * as styleRefs from './PromoteSignInSection.css';

const usePromoteSignInImage = () => {
  const { country } = useMelwaysInfo();
  switch (country) {
    case 'au':
      return promoteSignInImageAU;
    case 'nz':
      return promoteSignInImageNZ;
    case 'sg':
      return promoteSignInImageSG;
    case 'my':
      return promoteSignInImageMY;
    case 'ph':
      return promoteSignInImagePH;
    case 'id':
      return promoteSignInImageID;
    case 'hk':
      return promoteSignInImageHK;
    case 'th':
      return promoteSignInImageTH;
    default:
      return promoteSignInImageAU;
  }
};

const PromoteSignInSectionView = ({
  header,
  content,
  imageSrc,
  widerWidget,
}: {
  header: string | ReactNode;
  content: ReactNode;
  imageSrc: string;
  widerWidget?: boolean;
}) => (
  <Box
    display="flex"
    alignItems="center"
    flexDirection={{ mobile: 'column', tablet: 'row' }}
    position="relative"
    gap="large"
    className={styleRefs.rootContainer}
  >
    <Box
      background="surface"
      borderRadius="large"
      paddingY="large"
      paddingX={{ mobile: 'xsmall', tablet: 'large' }}
      display="flex"
      flexDirection="column"
      gap="large"
      zIndex={1}
      className={[
        styleRefs.widgetContainer,
        {
          [styleRefs.widgetContainerWider]: widerWidget,
        },
      ]}
      position="relative"
    >
      {/* This workaround leverages the `boxShadow` property on a Box component instead of defining it through CSS. 
          Need to ensures the `borderRadius` matches that of the parent container for consistent styling. */}
      <Box
        position="absolute"
        boxShadow="large"
        borderRadius="large"
        width="full"
        height="full"
        left={0}
        top={0}
        display={{ mobile: 'none', tablet: 'block' }}
        className={styleRefs.boxShadowBox}
      />
      <Heading level="2">{header}</Heading>
      {content}
    </Box>
    <Box
      overflow="hidden"
      position={{ mobile: 'relative', tablet: 'absolute' }}
      right={0}
      height="full"
      borderRadius="large"
      className={styleRefs.imageContainer}
    >
      <Box component="img" src={imageSrc} className={styleRefs.image} alt="" />
    </Box>
  </Box>
);

/**
 * aka v1
 */
export const PromoteSignInSectionWithSellingPoints = () => {
  const promoteSignInImage = usePromoteSignInImage();
  const signInLink = useReturnUrl({ mode: 'signin' });
  const { language } = useMelwaysInfo();

  return (
    <PromoteSignInSectionView
      header="Looking for the right job? You’re more likely to find it on SEEK."
      content={
        <>
          <Stack space="small">
            <Text>Sign in for better job matches: </Text>
            <List type="icon" space="small" icon={<IconTick tone="positive" />}>
              <Text>Smarter search to find all the right jobs</Text>
              <Text>Job recommendations personalised to you</Text>
              <Text>Instant job alerts so you never miss an opportunity</Text>
            </List>
          </Stack>
          <Box style={{ alignSelf: 'start' }}>
            <ButtonLink tone="formAccent" href={signInLink}>
              Get started <IconArrow direction="right" />
            </ButtonLink>
          </Box>
        </>
      }
      imageSrc={promoteSignInImage}
      widerWidget={language === 'id'}
    />
  );
};

/**
 * aka v2
 */
export const PromoteSignInSectionWithMultiCtas = () => {
  const registerLink = useReturnUrl({ mode: 'register' });
  const signInLink = useReturnUrl({ mode: 'signin' });
  const promoteSignInImage = usePromoteSignInImage();
  return (
    <PromoteSignInSectionView
      header="Get the best matches on SEEK"
      content={
        <>
          <Stack space="small">
            <Text>Sign in to see the right jobs for you</Text>
          </Stack>
          <ButtonLink tone="formAccent" href={signInLink}>
            Sign in
          </ButtonLink>
          <Text>
            Don’t have an account?{' '}
            <TextLink href={registerLink}>Register</TextLink>
          </Text>
        </>
      }
      imageSrc={promoteSignInImage}
    />
  );
};
